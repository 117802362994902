(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("zepto"), require("image-client-api"), require("warmupUtilsLib"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "zepto", "image-client-api", "warmupUtilsLib"], factory);
	else if(typeof exports === 'object')
		exports["coreUtils"] = factory(require("lodash"), require("zepto"), require("image-client-api"), require("warmupUtilsLib"));
	else
		root["coreUtils"] = factory(root["lodash"], root["zepto"], root["image-client-api"], root["warmupUtilsLib"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__54__) {
return 