'use strict'
const _ = require('lodash')
const urlUtils = require('./urlUtils')

function isExperimentOpen() {
    const exp = urlUtils.parseUrl(_.get(window, ['location', 'href'], '')).query.experiments
    return _.includes(exp, 'sv_wSpy')
}

const settings = {
    ignoredEvents: [
        'wixCode.fileSystem.getRoots',
        'wixCode.log.trace',
        'bi.event',
        'platform.reportAPICallBI',
        'ds_GETTER'
    ],
    stackFilter: /wSpy|publicMethodUtils|bundle.js|ActionQueue.js|require.min.js|main-r.min.js|observableDataUtil.js|lodash|mobxDataHandlers.js/i
}
const MAX_LOG_SIZE = 10000
const DEFAULT_LOGS_COUNT = 300
const GROUP_MIN_LEN = 5
const {ignoredEvents, stackFilter} = settings

function shouldLog(record) {
    return Array.isArray(record) && !ignoredEvents.includes(record[0])
}

const logNames = []
const systemProps = ['index', 'time', '_time', 'mem']
const wSpy = {
    isExperimentOpen,
    log(logName, record, takeFrom) {
        if (!shouldLog(record)) {
            return
        }
        this.index = this.index || 1
        this.logs = this.logs || {}
        if (!logNames.includes(logName)) {
            logNames.push(logName)
            this.logs[logName] = []
        }
        /*eslint santa/no-side-effects:0*/
        record.index = this.index++
        record.source = this.source(takeFrom)
        const now = new Date()
        record._time = `${now.getSeconds()}:${now.getMilliseconds()}`
        record.time = now.getTime()
        record.mem = window.performance.memory.usedJSHeapSize / 1000000
        record.push(record.source)
        if (this.logs[logName].length > MAX_LOG_SIZE) {
            this.logs[logName] = this.logs[logName].slice(-1 * Math.floor(MAX_LOG_SIZE / 2))
        }
        this.logs[logName].push(record)
    },
    getCallbackName(cb, takeFrom) {
        if (!cb) {
            return
        }
        if (!cb.name || _.startsWith(cb.name, 'bound ')) {
            return _.head(cb.source || this.source(takeFrom))
        }
        return _.trim(cb.name)
    },
    logCallBackRegistration(cb, logName, record, takeFrom) {
        cb.source = this.source(takeFrom)
        this.log(logName, [this.getCallbackName(cb, takeFrom), ...record], takeFrom)
    },
    logCallBackExecution(cb, logName, record, takeFrom) {
        this.log(logName, [this.getCallbackName(cb, takeFrom), cb.source, ...record], takeFrom)
    },
    spyMobx(mobx) {
        mobx.spy(e => {
            if (e.spyReportEnd) {
                return
            }
            if (e.type === 'update') {
                const src = this.source()
                this.log('mobx', [`update: ${e.name}`, ...src, e.newValue, e])
            }
        })
    },
    // developer usage: window.parent.xx
    purge(count) {
        const countFromEnd = -1 * (count || DEFAULT_LOGS_COUNT)
        logNames.forEach(log => { this.logs[log] = this.logs[log].slice(countFromEnd) })
    },
    clear() {
        logNames.forEach(log => { this.logs[log] = [] })
    },
    recent(count) {
        const countFromEnd = -1 * (count || DEFAULT_LOGS_COUNT)
        return this.merged().slice(countFromEnd)
    },
    merged(filter) {
        return [].concat.apply([], logNames.map(module =>
            this.logs[module].map(arr => {
                const res = [arr.index, module, ...arr]
                systemProps.forEach(p => {
                    res[p] = arr[p]
                })
                return res
            })))
            .filter((e, i, src) => !filter || filter(e, i, src))
            .sort((x, y) => x.index - y.index)
    },
    grouped(filter) {
        const merged = this.merged(filter)
        const countFromEnd = -1 * DEFAULT_LOGS_COUNT
        return [].concat.apply([], merged.reduce((acc, curr, i, arr) => {
            const group = acc[acc.length - 1]
            if (!group) {return [newGroup(curr)]}
            if (curr[1] === group[0][1]) {
                group.push(curr)
            } else {
                if (group.length > GROUP_MIN_LEN) {group.unshift(`[${group.length}] ${group[0][1]}`)}
                acc.push(newGroup(curr))
            }
            if (i === arr.length - 1 && group.length > GROUP_MIN_LEN) {
                group.unshift(`[${group.length}] ${group[0][1]}`)
            }
            return acc
        }, []).map(e => e.length > GROUP_MIN_LEN ? [e] : e))
            .slice(countFromEnd)
            .map((x, i, arr) => {
                const delay = i === 0 ? 0 : x.time - arr[i - 1].time
                x[0] = `${x[0]} +${delay}`
                return x
            })
        function newGroup(rec) {
            const res = [rec]
            res.time = rec.time
            return res
        }
    },
    groupedNoMobx(filter) {
        return this.grouped((e, i, src) => e[1] !== 'mobx' && (!filter || filter(e, i, src)))
    },
    enabled() {
        return true // enabled is noop if the experiment is closed. (only for devs)
    },
    source(takeFrom) {
        Error.stackTraceLimit = 50
        const windows = [window]
        while (windows[0] !== windows[0].parent) {
            windows.unshift(windows[0].parent)
        }
        let stackTrace = windows.reverse().map(win => new win.Error().stack).join('\n').split(/\r|\n/).map(x => x.trim()).slice(4)
            .filter(line => line !== 'Error')
            .filter(line => !stackFilter.test(line))
        if (takeFrom) {
            const firstIndex = stackTrace.findIndex(line => _.includes(line, takeFrom))
            stackTrace = stackTrace.slice(firstIndex + 1)
        }
        const line = stackTrace[0] || ''
        return [
            line.split('at ').pop().split(' ')[0],
            line.split('/').pop().slice(0, -1).trim(),
            ...stackTrace
        ]
    }
}
const noopSpy = _.mapValues(wSpy, () => _.noop)

function hasWindowWithParent() {
    return typeof window !== 'undefined' && _.get(window, 'parent')
}

function has_wSpy() {
    try {
        return typeof window !== 'undefined' && _.get(window, 'parent.wSpy')
    } catch (e) {
        return false
    }
}

function moduleExports() {
    try {
        if (has_wSpy()) {
            return has_wSpy()
        }
        if (hasWindowWithParent() && isExperimentOpen()) {
            window.parent.wSpy = wSpy
            return wSpy
        }
        return noopSpy
    } catch (e) {
        return noopSpy
    }
}

module.exports = moduleExports()
